"use client";

import { Button } from "@/components/atoms/button";
import { getProduct } from "@/functions/data";
import React, { useEffect, useState } from "react";
import uniqBy from "lodash.uniqby";
import ProductCard from "./ProductCard";

export default function ProductList({
  dataProducts = [],
  apiShowMoreProps = {
    page: 2,
    limit: 8,
    disabled:false,
  },
  productCardProps,
}: {
  dataProducts: DataProduct[];
  apiShowMoreProps?: ParamsProduct;
  productCardProps?: Omit<ProductCardType, "datas">;
}) {
  const { page = 2, limit = 8, disabled } = apiShowMoreProps;

  const [datas, setDatas] = useState(dataProducts);
  const [numPage, setNumPage] = useState(page);
  const [disableNextPage, setDisableNextPage] = useState(disabled);
  const [loading, setLoading] = useState({ nextPage: false, getAllOld: false });

  async function getAllOld(num: number = page) {
    if (num <= page) setLoading((old) => ({ ...old, getAllOld: true }));
    try {
      let payload: ParamsProduct = {
        page: num,
        limit: limit,
      };
      // console.log(payload);

      let datasProductOld: DataProduct[] = await getProduct(payload);

      datasProductOld =
        !datasProductOld || datasProductOld?.length <= 0 ? [] : datasProductOld;

      // console.log(datasProductOld?.length);

      // disable next page jika data terakhir page kosong
      if (num > 2 && datasProductOld?.length <= 0) setDisableNextPage(true);

      // console.log(datasProductOld?.length);

      let isDataOld: {
        stock: DataProduct[];
        emptyStock: DataProduct[];
      } = {
        stock: [],
        emptyStock: [],
      };

      let stock = datasProductOld?.filter((item) => item?.stock > 0) ?? [];
      let emptyStock =
        datasProductOld?.filter((item) => item?.stock <= 0) ?? [];

      isDataOld.stock = stock?.length > 0 ? stock : [];
      isDataOld.emptyStock = emptyStock?.length > 0 ? emptyStock : [];

      setDatas((val) =>
        val?.length > 0
          ? uniqBy(
              [...val, ...isDataOld?.stock, ...isDataOld?.emptyStock],
              "_id"
            )
          : uniqBy([...isDataOld?.stock, ...isDataOld?.emptyStock], "_id")
      );
    } catch (error) {
      console.log("get all old product: ", error);
    } finally {
      setLoading((old) => ({ ...old, getAllOld: false }));
    }
  }

  async function nextPage(num: number) {
    setLoading((old) => ({ ...old, nextPage: true }));
    try {
      await getAllOld(num);
      setNumPage(num);
    } catch (error) {
      console.log(`error next page : ${error}`);
    } finally {
      setLoading((old) => ({ ...old, nextPage: false }));
    }
  }

  useEffect(() => {
    if (dataProducts?.length > 0) setDatas(dataProducts);
  }, [dataProducts]);

  useEffect(() => {
    if (page > 0) setNumPage(page);
  }, [page]);

  return (
    <>
      <ProductCard datas={datas} {...productCardProps} />
      {!disableNextPage && (
        <div className="w-full justify-center flex mt-12">
          <div className="w-fit">
            <Button
              variant="outlined"
              size="small"
              onClick={() => nextPage(numPage + 1)}
              disabled={loading?.nextPage || disableNextPage}
              loading={loading?.nextPage}
            >
              Show More
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
