import React, { Fragment } from "react";
import { Product } from ".";
import clsx from "clsx";

export default function ProductCard({
  datas = [],
  grid = 4,
}: ProductCardType) {
  return (
    <div
      className={clsx(
        "grid @3xl:grid-cols-3 grid-cols-2 gap-4",
        grid == 4 ? "@5xl:grid-cols-4" : "",
      )}
    >
      {datas?.map((a, b) => (
        <Fragment key={b}>
          <Product a={a} />
        </Fragment>
      ))}
    </div>
  );
}
