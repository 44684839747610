"use client";

import React from "react";
import "./banner.css";

export default function TextBanner() {
  return (
    <div className="relative mx-auto min-h-screen h-full overflow-hidden flex w-full flex-col @md:gap-4 gap-2">
      <div className="hero-video">
        <video autoPlay loop muted>
          <source
            src={"https://media.nbrscorp.co.id/gon-dev/banner-video.mp4"}
            type="video/mp4"
          />
        </video>
      </div>
      <button
        onClick={() => {
          const section = document.getElementById("gon");
          if (section) section.scrollIntoView({ behavior: "smooth" });
        }}
        className="absolute scroll-smooth hidden w-40 h-40 p-1 rounded-full 50 inset-x-2/3 inset-y-[40%] text-center justify-center @md:flex items-center bg-secondaryGON-100/50 backdrop-blur-sm group"
      >
        <p className="font-poppins uppercase text-greyscaleGON-900">
          Order Now
        </p>
      </button>
      <div className="absolute @md:bottom-36 bottom-32 justify-center text-center flex flex-col w-full items-center">
        <button
          onClick={() => {
            const section = document.getElementById("gon");
            if (section) section.scrollIntoView({ behavior: "smooth" });
          }}
          className="w-24 h-24 bg-secondaryGON-100/50 rounded-full duration-300 mb-4 transition-all @md:hidden"
        >
          <p className="font-poppins text-sm uppercase text-greyscaleGON-900">
            Order Now
          </p>
        </button>
        <div className="@md:max-w-[45dvw] mx-auto max-w-[90dvw]">
          <p className="font-poppins @md:text-sm text-xs text-shadesGON-50 @md:flex hidden">
            NBRS Corp bercita-cita untuk merubah paradigma sebagian besar
            masyarakat Indonesia yang memandang bahwa wanita berbusana muslimah
            itu kurang modis. NBRS Corp mencoba menggebrak stigma itu dengan
            menciptakan produk busana muslim yang modis, cantik, elegan dan
            unik.
          </p>
          <p className="font-poppins text-xl capitalize text-shadesGON-50 @md:hidden flex bg-clip-[polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)]">
            Busana muslim yang modis, cantik, elegan dan unik. #NibrasCorp
          </p>
        </div>
      </div>
      <div className="absolute @md:bottom-5 bottom-2 w-full">
        <div className="relative w-full overflow-hidden whitespace-nowrap">
          <div className="animate-carousel inline-block">
            <h1 className="@md:text-5xl text-3xl font-work-sans text-shadesGON-50/50 mr-12 uppercase bg-clip-[polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)]">
              Elegansi Gamis Muslim dari Nibras. Elegansi Gamis Muslim dari
              Nibras. Elegansi Gamis Muslim dari Nibras. Elegansi Gamis Muslim
              dari Nibras. Elegansi Gamis Muslim dari Nibras. Elegansi Gamis
              Muslim dari Nibras.
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
