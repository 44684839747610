"use client";

import { ChatCSPixelFB } from "@/functions/pixelEvents";
import Link from "next/link";
import React from "react";

export default function Perusahaan() {
  return (
    <li className="@xl:min-w-[7rem]">
      <div className="flex flex-col gap-6">
        <p className="font-poppins font-semibold text-greyscaleGON-600 uppercase">
          Perusahaan
        </p>
        <div className="flex flex-col gap-2">
          {[
            {
              url: `https://wa.me/628118046243?text=${`Halo, Admin. Saya butuh bantuan`}`,
              title: "Bantuan"
            },
            { url: "#", title: "Tentang Kami" },
            { url: "#", title: "Hubungi Kami" },
            {
              url: "https://gudangonlinenibras.co.id/terms",
              title: "Syarat, Ketentuan, dan Privasi"
            },
            { url: "#", title: "FAQ" }
          ]?.map((a, b) => {
            return (
              <Link
                href={a.url}
                key={b}
                onClick={() => {
                  true
                    ? ChatCSPixelFB({
                        content_name: a.title,
                        content_category: "Halo, Admin. Saya butuh bantuan",
                        content_ids: "628118046243"
                      })
                    : null;
                }}
                target="_blank"
                className="group"
              >
                <p className="capitalize font-poppins font-medium text-greyscaleGON-600 group-hover:underline group-hover:underline-offset-4">
                  {a.title}
                </p>
              </Link>
            );
          })}
        </div>
      </div>
    </li>
  );
}
